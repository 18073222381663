import {
  ISO_SUFFIX,
  ISO_DATE_REGEX,
  LOCALE_SHORTHANDS,
  STATISTIC_CONFIGS,
} from '../constants';

import {format, formatDistance, formatISO, subDays} from 'date-fns';
import {utcToZonedTime} from 'date-fns-tz';
import i18n from 'i18next';

let locale = null;
const numberFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 1,
});

const getLocale = () => {
  import('date-fns/locale/').then((localePackage) => {
    locale =
      localePackage[
        LOCALE_SHORTHANDS[i18n.language || window.localStorage.i18nextLng]
      ];
  });
};

export const isDevelopmentOrTest = () => {
  if (
    process &&
    (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test')
  )
    return true;
  return false;
};

export const getDateGMT = () => {
  return utcToZonedTime(new Date(), 'Etc/GMT');
};

export const getDateGMTISO = () => {
  return formatISO(getDateGMT(), {representation: 'date'});
};

export const getDateGMTYesterday = () => {
  return subDays(getDateGMT(), 1);
};

export const getDateGMTYesterdayISO = () => {
  return formatISO(getDateGMTYesterday(), {representation: 'date'});
};

export const formatLastUpdated = (unformattedDate) => {
  getLocale();
  return formatDistance(new Date(unformattedDate), new Date(), {
    locale: locale,
  });
};

export const parseDateGMT = (unformattedDate) => {
  if (!unformattedDate) {
    return getDateGMT();
  }
  if (
    typeof unformattedDate === 'string' &&
    unformattedDate.match(ISO_DATE_REGEX)
  ) {
    unformattedDate += ISO_SUFFIX;
  }
  return utcToZonedTime(new Date(unformattedDate), 'Etc/GMT');
};

export const formatDate = (unformattedDate, formatString) => {
  if (!unformattedDate) return '';
  if (
    typeof unformattedDate === 'string' &&
    unformattedDate.match(ISO_DATE_REGEX)
  )
    unformattedDate += ISO_SUFFIX;
  const date = utcToZonedTime(new Date(unformattedDate), 'Etc/GMT');
  return format(date, formatString, {
    locale: locale,
  });
};

export const formatDateGMT = (dateObj) => {
  return !dateObj
    ? null
    : formatDate(dateObj, "yyyy-MM-dd'T'HH:mm:ss-00:00", {
        locale: locale,
      });
};

export const abbreviateNumber = (number) => {
  const numberCleaned = Math.round(Math.abs(number));
  // eslint-disable-next-line
  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(numberCleaned);
};

export const formatValue = (value, option = '', statistic) => {
  if (option === 'string') {
    return value;
  } else if (
    isNaN(value) ||
    (statistic && STATISTIC_CONFIGS[statistic]?.hideZero && value === 0)
  ) {
    return '-';
  } else if (option === 'long') {
    return numberFormatter.format(
      Math.abs(value) < 1 ? value : Math.round(value)
    );
  } else if (option === 'short') {
    return abbreviateNumber(value);
  } else if (option === '%') {
    return `${numberFormatter.format(value)}%`;
  } else if (option === '') {
    return numberFormatter.format(value);
  }
};

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const toTitleCase = (str) => {
  if (!str) return '';
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const getStatistic = (
  data,
  type,
  statistic,
  {
    expiredDate = null,
    normalizedByPopulationPer = null,
    movingAverage = false,
    canBeNaN = false,
    isCompleteData = false,
  } = {}
) => {
  let multiplyFactor = 1;
  if (type === 'd' && movingAverage) {
    type = 'd7';
    multiplyFactor *= 1 / 7;
  }

  const population = isCompleteData ? data?.m?.pc : data?.m?.pa;

  if (typeof isCompleteData === 'undefined') {
    const sessionComplete = sessionStorage.getItem('isCompleteData');
    isCompleteData = sessionComplete === 'true';
  }

  if (normalizedByPopulationPer === 'million') {
    multiplyFactor *= 1e6 / population;
  } else if (normalizedByPopulationPer === '10k') {
    multiplyFactor *= 1e4 / population;
  } else if (normalizedByPopulationPer === 'hundred') {
    multiplyFactor *= 1e2 / population;
  }

  let val;
  if (statistic === 'population') {
    val = type === 'total' ? population : 0;
  } else if (type === 'total') {
    val = data?.[isCompleteData ? 'tc' : 'ta']?.[statistic];
  } else {
    val = data?.[type]?.[statistic];
  }

  const statisticConfig = STATISTIC_CONFIGS[statistic];
  multiplyFactor = (statisticConfig?.nonLinear && 1) || multiplyFactor;

  let result = multiplyFactor * val;
  if (!canBeNaN) {
    result = (!isNaN(result) && result) || 0;
  }
  if (!statisticConfig?.canBeInfinite) {
    result = ((isNaN(result) || isFinite(result)) && result) || 0;
  }
  return canBeNaN && !result ? null : result;
};

export const fetcher = (url) => {
  return fetch(url, {mode: 'cors'}).then(async (response) => {
    const data = await response.json();
    if (data && data.NOTICE) {
      delete data.NOTICE;
    }

    return data;
  });
};

export function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export const spike = (length, width = 8) =>
  `M${-width / 2},0L0,${-length}L${width / 2},0`;
